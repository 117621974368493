<template>
  <div class="trademarkArchives_out clearfix" id="trademarkInfor">
    <div style="height: 74px"></div>
    <input id="copy_content" type="text" value=""  style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"/>
    <el-button class="btnPdf"  @click="pdf" type="primary">{{$t(`tra_detail_en.btnPdf`)}}</el-button>
    <div class="wrapBox" id="pdf">
      <div class="trademarkArchives_in" :class="{en:$i18n.locale=='en-US'}">
        <div class="tradeMarkTop">
          <ul :class="[{active: !isCN}]">
            <li>
              <div class="trademarkArchivesTop clearfix">
                <div
                  v-if="mineInfor.image_url"
                  class="imgBox"
                  style="width: 210px;height: 215px;"
                >
                  <img
                    class="imghover"
                    :src="queryDataUrl(mineInfor.image_url)"
                    style="height: auto;max-width: 89%;max-height: 89%;"
                  />
                </div>
                <div
                  v-else
                  class="imgBox"
                  style="width: 210px;height: 215px;overflow: hidden;"
                >
                  <span>{{mineInfor.sbmc}}</span>
                </div>
                <div style="width:72%;margin-left: 40px;position: absolute;right: 2px; bottom: 0;"></div>
              </div>
            </li>
            <li>
              <div class="leftLi">
                <div style="display: flex" class="publicD">
                  <span class="littleTittle">{{$t(`tra_detail_en.fild[0]`)}}</span>
                  <span class="fontD trademarkName">{{mineInfor.countryZhName}}</span>
                  <div class="copy" v-if="mineInfor.countryZhName" style="display: flex">
                    <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.countryZhName)">复制</el-button>
                  </div>
                </div>
                <div class="publicD">
                  <span class="littleTittle">{{$t(`tra_detail_en.fild[1]`)}}</span>
                  <span
                    class="fontD"
                    v-if="mineInfor.sbmc !=''"
                    style="display: inline-block;vertical-align: top;"
                  >{{mineInfor.sbmc}}</span>
                  <span class="fontD" v-if="mineInfor.sbmc ==''">{{$t(`tra_detail_en.leftLi[0]`)}}</span>
                  <div class="copy" v-if="mineInfor.sbmc" style="display: inline-flex;align-items: center;vertical-align: bottom">
                    <el-button style="display: inline-block;margin-bottom: 5px" size="mini" round @click="copy(mineInfor.sbmc)">复制</el-button>
                    <img @click="baidu(mineInfor.sbmc)"  style="width: 21px;cursor: pointer;margin-left: 5px;margin-bottom: 5px" src="../../assets/images/query/baidu.png" title="百度">
                  </div>
                </div>
                <div class="publicD">
                  <span class="littleTittle">{{$t(`tra_detail_en.leftLi[1]`)}}</span>
                  <span class="fontD">{{mineInfor.appno}}</span>
                  <div class="copy" v-if="mineInfor.appno" style="display: inline-block">
                    <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.appno)">复制</el-button>
                  </div>
                </div>
                <div class="publicD">
                  <span class="littleTittle">{{$t(`tra_detail_en.leftLi[2]`)}}</span>
                  <span class="fontD">{{mineInfor.regno}}</span>
                  <div class="copy" v-if="mineInfor.regno" style="display: inline-block">
                    <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.regno)">复制</el-button>
                  </div>
                </div>

                <div class="publicD">
                  <span class="littleTittle">{{$t(`tra_detail_en.leftLi[3]`)}}</span>
                  <span class="fontD">{{mineInfor.trialdate}}</span>
                  <div class="copy" v-if="mineInfor.trialdate" style="display: inline-block">
                    <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.trialdate)">复制</el-button>
                  </div>
                </div>
                <div class="publicD">
                  <span class="littleTittle">{{$t(`tra_detail_en.classTable[9]`)}}</span>
                  <span class="fontD">{{mineInfor.tmstatus}}</span>
                  <div class="copy" v-if="mineInfor.tmstatus" style="display: inline-block">
                    <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.tmstatus)">复制</el-button>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div style="height:250px;">
                <div class="leftLiOne">
<!--                  <div class="publicD">-->
<!--                    <span class="littleTittle"></span>-->
<!--                    <span class="fontD"></span>-->
<!--                  </div>-->
                  <div class="publicD">
                    <span class="littleTittle">{{$t(`tra_detail_en.fild[2]`)}}</span>
                    <span class="fontD">{{mineInfor.tmtype}}</span>
                    <div class="copy" v-if="mineInfor.tmtype" style="display: inline-block">
                      <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.tmtype)">复制</el-button>
                    </div>
                  </div>
                  <div class="publicD">
                    <span class="littleTittle">{{$t(`tra_detail_en.leftLi[4]`)}}</span>
                    <span class="fontD">{{mineInfor.appdate}}</span>
                    <div class="copy" v-if="mineInfor.appdate" style="display: inline-block">
                      <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.appdate)">复制</el-button>
                    </div>
                  </div>
                  <div class="publicD">
                    <span class="littleTittle">{{$t(`tra_detail_en.fild_1[2]`)}}</span>
                    <span class="fontD">{{mineInfor.regdate}}</span>
                    <div class="copy" v-if="mineInfor.regdate" style="display: inline-block">
                      <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.regdate)">复制</el-button>
                    </div>
                  </div>
                  <div class="publicD">
                    <span class="littleTittle">{{$t(`tra_detail_en.fild[3]`)}}</span>
                    <span class="fontD">{{mineInfor.validdate}}</span>
                    <div class="copy" v-if="mineInfor.validdate" style="display: inline-block">
                      <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.validdate)">复制</el-button>
                    </div>
                  </div>
                  <div class="publicD">
                    <span class="littleTittle">{{$t(`tra_detail_en.seniority_box[4]`)}}</span>
                    <span class="fontD">{{mineInfor.prioritydate}}</span>
                    <div class="copy" v-if="mineInfor.prioritydate" style="display: inline-block">
                      <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.prioritydate)">复制</el-button>
                    </div>
                  </div>
                  <div class="publicD">
                    <span class="littleTittle">{{$t(`tra_detail_en.leftLi[5]`)}}</span>
                    <span class="fontD">{{mineInfor.inter_reg_num}}</span>
                    <div class="copy" v-if="mineInfor.inter_reg_num" style="display: inline-block">
                      <el-button style="display: inline-block" size="mini" round @click="copy(mineInfor.inter_reg_num)">复制</el-button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="ApplicantInformation">
          <b>{{$t(`tra_detail_en.classTable[0]`)}}</b>
          <div class="trademarkName-content">
            <span
              class="littleTittle"
              style="display: inline-block;width: 130px;margin-right: 50px;"
            >{{$t(`tra_detail_en.fild[5]`)}}</span>
            <span class="trademarkName-text">{{mineInfor.applicant_country_zhname}}</span>
            <el-button v-if="mineInfor.applicant_country_zhname" @click="copy(mineInfor.applicant_country_zhname)" size="mini" round class="copy">复制</el-button>
          </div>
          <div class="trademarkName-content">
            <span
              class="littleTittle"
              style="display: inline-block;width: 130px;margin-right: 50px;"
            >{{$t(`tra_detail_en.fild[6]`)}}</span>
            <span style="color: #1F46D7" @click="guanlian(mineInfor.applicant_en||mineInfor.tmapplicant,3)" class="trademarkName-text" v-if="mineInfor.countryZhName == '中国香港' || mineInfor.countryZhName == '中国澳门' || mineInfor.countryZhName == '中国台湾'">{{mineInfor.tmapplicant||mineInfor.applicant_en}}</span><!-- 港澳台优先显示tmapplicant,其他优先显示applicant_en-->
            <span style="color: #1F46D7" @click="guanlian(mineInfor.applicant_en||mineInfor.tmapplicant,3)" class="trademarkName-text" v-else>{{mineInfor.applicant_en||mineInfor.tmapplicant}}</span><!-- 港澳台优先显示tmapplicant,其他优先显示applicant_en-->
            <el-button v-if="mineInfor.applicant_en||mineInfor.tmapplicant"  @click="copy(mineInfor.countryZhName == '中国香港' || mineInfor.countryZhName == '中国澳门' || mineInfor.countryZhName == '中国台湾' ? mineInfor.tmapplicant||mineInfor.applicant_en :mineInfor.applicant_en||mineInfor.tmapplicant)" size="mini" round class="copy">复制</el-button>
            <img class="copy" v-if="mineInfor.applicant_en||mineInfor.tmapplicant" @click="baidu(mineInfor.countryZhName == '中国香港' || mineInfor.countryZhName == '中国澳门' || mineInfor.countryZhName == '中国台湾' ? mineInfor.tmapplicant||mineInfor.applicant_en :mineInfor.applicant_en||mineInfor.tmapplicant)"  style="width: 21px;cursor: pointer;margin-left: 5px;vertical-align: middle" src="../../assets/images/query/baidu.png" title="百度">
<!--            <img class="copy" @click="guanlian(mineInfor.applicant_en||mineInfor.tmapplicant,3)" style="width: 21px;cursor: pointer;margin-left: 5px;vertical-align: middle"  src="../../assets/images/query/guanlian.png" title="关联查询">-->
          </div>
          <div class="trademarkName-content">
            <span
              class="littleTittle"
              style="display: inline-block;width: 130px;margin-right: 50px;"
            >{{$t(`tra_detail_en.fild[7]`)}}</span>
            <span class="trademarkName-text" v-if="mineInfor.countryZhName == '中国香港' || mineInfor.countryZhName == '中国澳门' || mineInfor.countryZhName == '中国台湾'">{{mineInfor.tmaddress||mineInfor.address_en}}</span>
            <span class="trademarkName-text" v-else>{{mineInfor.address_en||mineInfor.tmaddress}}</span>
            <el-button v-if="mineInfor.address_en||mineInfor.tmaddress" @click="copy(mineInfor.countryZhName == '中国香港' || mineInfor.countryZhName == '中国澳门' || mineInfor.countryZhName == '中国台湾' ? mineInfor.tmaddress||mineInfor.address_en : mineInfor.address_en||mineInfor.tmaddress)" size="mini" round class="copy">复制</el-button>
          </div>
          <div class="trademarkName-content" v-if="mineInfor.countryZhName == '马德里'">
            <span
              class="littleTittle"
              style="display: inline-block;width: 130px;margin-right: 50px;"
            >{{$t(`tra_detail_en.classTable[2]`)}}</span>
            <span class="trademarkName-text">{{mineInfor.agreement_country_zhname}}<span v-if="mineInfor.agreement_country_zhname && mineInfor.protocol_country_zhname">;</span>{{mineInfor.protocol_country_zhname}}</span>
            <el-button v-if="mineInfor.agreement_country_zhname || mineInfor.protocol_country_zhname" @click="copy(mineInfor.agreement_country_zhname && mineInfor.protocol_country_zhname ? mineInfor.agreement_country_zhname + ';' + mineInfor.protocol_country_zhname : mineInfor.agreement_country_zhname || mineInfor.protocol_country_zhname )" size="mini" round class="copy">复制</el-button>
          </div>
        </div>
        <div class="CorrespondenceInformation">
          <b>{{$t(`tra_detail_en.classTable[3]`)}}</b>
          <div class="trademarkName-content">
            <span
              class="littleTittle"
              style="display: inline-block;width: 130px;margin-right: 50px;"
            >{{$t(`tra_detail_en.fild_1[1]`)}}</span>
            <span class="trademarkName-text">{{mineInfor.correspondence_country_zhName}}</span>
            <el-button v-if="mineInfor.correspondence_country_zhName" @click="copy(mineInfor.correspondence_country_zhName)" size="mini" round class="copy">复制</el-button>
          </div>
          <div class="trademarkName-content">
            <span
              class="littleTittle"
              style="display: inline-block;width: 130px;margin-right: 50px;"
            >{{$t(`tra_detail_en.classTable[4]`)}}</span>
            <span class="trademarkName-text">{{mineInfor.correspondence_name}}</span><!-- 优先显示applicant_en-->
            <el-button v-if="mineInfor.correspondence_name" @click="copy(mineInfor.correspondence_name)" size="mini" round class="copy">复制</el-button>
          </div>
          <div class="trademarkName-content">
            <span
              class="littleTittle"
              style="display: inline-block;width: 130px;margin-right: 50px;"
            >{{$t(`tra_detail_en.classTable[5]`)}}</span>
            <span class="trademarkName-text">{{mineInfor.correspondence_address}}</span>
            <el-button v-if="mineInfor.correspondence_address" @click="copy(mineInfor.correspondence_address)" size="mini" round class="copy">复制</el-button>
          </div>
        </div>
        <div style="padding-bottom: 12px;">
          <table class="classTable" v-if="mineInfor.country === 'CA'">
            <tr>
              <th>{{$t(`tra_detail_en.classTable[6]`)}}</th>
              <th>{{$t(`tra_detail_en.classTable[7]`)}}</th>
            </tr>
            <tr v-for="(item,index) in CanadaWebService" :key="index">
              <td>{{item.niceCode}}</td>
              <td style="position: relative">{{item.value}}<el-button  @click="copy(item.value)" size="mini" round class="copy">复制</el-button></td>
            </tr>
          </table>
          <table class="classTable" v-else>
            <tr>
              <th>{{$t(`tra_detail_en.classTable[6]`)}}</th>
              <th>{{$t(`tra_detail_en.classTable[7]`)}}</th>
            </tr>
            <tr v-for="(item,index) in webClassService" :key="index">
              <td>{{item.niceCode}}</td>
              <td style="position: relative">{{item.value}}<el-button  @click="copy(item.value)" size="mini" round class="copy">复制</el-button></td>
            </tr>
          </table>
          <div
            class="ckgd"
            @click="appli"
            v-show="appliShow"
            v-if="webClassServiceOne.length > 4"
            style="cursor: pointer;"
          >{{$t(`tra_detail_en.classTable[8]`)}}</div>
          <div class="ckgd" @click="appliOne" v-show="appliOneShow" style="cursor: pointer;">收起</div>
        </div>
        <div class="tradeMarkProgre" v-if="infor.country != 'WO'">
          <span>{{$t('query_lang.ddProcedure')}}</span>
          <table class="progressTop">
            <tr>
              <th></th>
              <th style="text-indent:22px;">{{$t('query_lang.dddate')}}</th>
              <th>{{$t('tra_detail_en.seniority_box[9]')}}</th>
            </tr>
            <tr v-for="(valList,index2) in flowData" :key="index2" class="progressBottom">
              <td class="inailw">
                <img src="../../assets/images/query/huilineLittleOne.png" alt />
              </td>
              <td>{{valList.flowdate}}</td>
              <td>{{valList.flowenname}}</td>
            </tr>
          </table>
        </div>
        <div class="tradeMarkProgre" v-else>
          <span style="font-size: 18px;display: inline-block;margin-bottom: 10px;">{{$t('query_lang.ddProcedure')}}</span>
          <ul class="switch-style">
            <li class="style1" :class="{active:(index + 1)==switchStyleChoosed}" @click="areaSwitch(index)"
                v-for="(item,index) in switchStyleList" :key="index">
              <span>{{item.text}}</span>
            </li>
          </ul>
          <el-collapse v-model="activeNames" v-for="item in flowData" :key="item.authorityCode">
            <el-collapse-item :title="item.authorityZhName" :name="item.authorityCode" class="progressItem">
              <table class="progressTop">
                <tr>
                  <th></th>
                  <th style="text-indent:22px;">{{$t('query_lang.dddate')}}</th>
                  <th>{{$t('query_lang.seniority_box[9]')}}</th>
                </tr>
                <tr class="progressBottom" v-for="(list, index) in item.progress" :key="index">
                  <td class="inailw">
                    <img src="../../assets/images/query/huilineLittleOne.png" alt />
                  </td>
                  <td>{{list.progressDate}}</td>
                  <td>{{list.progressEvent}}</td>
                </tr>
              </table>
            </el-collapse-item>
          </el-collapse>

        </div>
      </div>
      <button
        class="leftBtn"
        @click="leftBtn"
        :disabled="firstIndex == 0"
        :class="firstIndex == 0 ? 'disabledBtn' : ''"
        :title="firstIndex == 0 ? $t('query_lang.seniority_box[10]') : ''">
        <i class="el-icon-arrow-left"></i>
      </button>
      <button
        class="rightBtn"
        @click="rightBtn"
        :disabled="firstIndex == trademarkIds.length-1"
        :class="firstIndex == trademarkIds.length-1 ? 'disabledBtn' : ''"
        :title="firstIndex == trademarkIds.length-1 ? $t('query_lang.seniority_box[11]') : ''">
        <i class="el-icon-arrow-right"></i>
      </button>

    </div>
    <!--<div class="btnBack" v-if="$i18n.locale=='en-US'">
            <button @click="back">{{$t('query_lang.return_')}}</button>
    </div>-->
    <!--底部-->

    <div class="trademarkArchivesBottom" :class="{en:$i18n.locale=='en-US'}">
      <!--<span class="print cur" @click="printContent()">{{$t('query_lang.Print')}}</span>&lt;!&ndash;打印&ndash;&gt;-->
      <!--<span class="error cur">{{$t('query_lang.Report_wrong_information')}}</span>&lt;!&ndash;错误信息反馈&ndash;&gt;-->
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  // import {rootImgUrl_qiufeng} from "../../service/rootUrl";
  export default {
    data() {
      return {
        isCN: true,
        activeNames: [],
        switchStyleList: [
          {text: "收折"},
          {text: "展开"},
        ],
        switchStyleChoosed: 1,
        data: {},
        list: {},
        flag: {},
        infor: {},
        mineInfor: {},
        urlParamIntcls: "",
        flowData: {},
        cotry: {},
        lang: this.$i18n.locale == "zh-CN" ? "zh" : "en",
        op: "",
        webClassService: [],
        webClassServiceOne: [],
        seniority: [],
        isClass: false,
        appliShow: true,
        appliOneShow: false,
        tmtypeOne: {
          普通商标: "Ordinary Trademark",
          证明商标: "Certification Trademark",
          集体商标: "Collective Trademark",
          特殊商标: "Special Trademark",
          一般: "Ordinary Trademark",
          证明: "Certification Trademark",
          集体: "Collective Trademark",
          特殊: "Special Trademark"
        },
        flowDataCountry: [], // 流程中的国家
        CanadaWebService: [],
        trademarkCountrys:[]
      };
    },
    components: {},
    created() {
      //打开多个窗口页面刷新不更新
      this.infor = this.$route.query;
      this.trademarkIds = JSON.parse(localStorage.getItem('douxing'))
      this.trademarkCountrys = JSON.parse(localStorage.getItem('douguo'))
      this.firstIndex = this.trademarkIds.indexOf(Number(this.infor.id))
      this.queryOtherTrademarkArchives(this.infor.id, this.infor.country);
    },
    mounted() {
      if(this.$i18n.locale === 'cn'){
        this.isCN = true
      }else {
        this.isCN = false
      }
    },
    methods: {
      //关联查询
      guanlian(value,index){
        let routeData = this.$router.resolve({ path: '/PQ',query: {
            value: index,
            searchText: value
          }});
        window.open(routeData.href, '_blank');
      },
      //百度一下
      baidu(item){
        // https://www.baidu.com/s?ie=UTF-8&wd=%E4%B8%AD%E5%9B%BD
        window.open(`https://www.baidu.com/s?ie=UTF-8&wd=${item}`);
      },

      //企查查
      qichacha(item){
        // https://www.qcc.com/web/search?key=%E5%8D%8E%E4%B8%BA
        window.open(`https://www.qcc.com/web/search?key=${item}`);
      },

      //百度一下
      copy(text){
        //获取要赋值的input的元素
        var inputElement =  document.getElementById("copy_content");
        //给input框赋值
        inputElement.value = text;
        //选中input框的内容
        inputElement.select();
        // 执行浏览器复制命令
        document.execCommand("Copy");
        //提示已复制
        this.$message('已复制');
      },

      //PDF导出功能
      pdf(){
        //pdf 打印置顶
        // document.body.scrollTop = document.documentElement.scrollTop = 0
        this.getPdf("pdf", this.mineInfor.sbmc);
      },
      queryDataUrl(data) {
        // if (data && !data.includes("http://")) return rootImgUrl_qiufeng + data;
        if (data && data.includes('data:image')) return data
        else if (data && !data.includes('http://')) return 'https://tmimage.lanternfish.cn' + data
        else return ''
      },
      areaSwitch(index) {
        if (this.switchStyleChoosed == (index + 1)) return;
        this.switchStyleChoosed = (index + 1);
        if(index == 0) {
          this.activeNames = [];
        } else if(index == 1) {
          this.activeNames = this.flowData.map(i => i.authorityCode);
        }
      },
      leftBtn() {
        if(this.firstIndex == 0) return
        this.queryOtherTrademarkArchives(this.trademarkIds[this.firstIndex - 1], this.trademarkCountrys[this.firstIndex - 1])
        this.firstIndex--;
      },
      rightBtn() {
        if(this.firstIndex == this.trademarkIds.length-1) return
        this.queryOtherTrademarkArchives(this.trademarkIds[this.firstIndex + 1], this.trademarkCountrys[this.firstIndex + 1])
        this.firstIndex++;
        this.leftDisabled = false;
      },
      jsonStringConvert(str) {
        let n = str.length;
        for (let i = 1; i < n; i++) {
          if (str[i-1] == '"' && str[i] == ':' && str[i + 1] == '"') {
            for (let j = i + 2; j < n-1; j++) {
              if (str[j] == '"') {
                if ((str[j + 1] != ',' && str[j + 1] != '}') || (str[j + 1] == ',' && str[j + 2] != '"')) {
                  const strArr = str.split('');
                  strArr[j] = "'";
                  str = strArr.join('');
                } else if (str[j + 1] == '}' || str[j + 1] == ',' ||  str[j + 1] == '"' || str[j - 1] == '{') {
                  break;
                }
              }
            }
          }
        }
        return str;
      },
      queryOtherTrademarkArchives(ids, country) {
        if(country == 'CN'){
          this.$router.push({
            path: "/query/detail",
            query: {
              id: ids,
              country: country
            }
          })
          return;
        }
        this.$axios.post("/common/query/searchByIds", {
          country: country,
          keywork: ids,
          lang: "zh"
        })
          .then(res => {
            if (res.data.status.code == 200) {
              this.mineInfor = res.data.trademark_list[0];
              this.webClassService = this.mineInfor.tmdetail || this.mineInfor.tmdetail_en || [];
              if(this.webClassService.length) {
                this.webClassService = this.webClassService.replace(/\\./g,'.')
                this.webClassService = this.webClassService.replace(/	/g,'.')
                try {
                  this.webClassService = JSON.parse(this.jsonStringConvert(this.webClassService));
                }catch (e) {
                  console.log(e,this.webClassService)
                }
                for (var i = 0; i < this.webClassService.length; i++) {
                  for (var key in this.webClassService[i]) {
                    if(parseInt(key)){
                      this.webClassService[i].niceCode = key;
                      this.webClassService[i].value = this.webClassService[i][key];
                    }else {
                      this.webClassService[i].niceCode = this.webClassService[i].niceCode;
                      this.webClassService[i].value = this.webClassService[i].value;
                    }
                    break;
                  }
                }
              }
              this.webClassService.map((item, index) => {
                if(item.niceCode === "00") {
                  this.webClassService.splice(index, 1)
                }
              })
              let CanadaWebServiceObj = {};
              CanadaWebServiceObj.niceCode = this.webClassService.map(item => item.niceCode).join(';');
              CanadaWebServiceObj.value = this.webClassService.map(item => item.value).join(';');
              this.CanadaWebService.push(CanadaWebServiceObj);
              this.flowData = res.data.trademark_list[0].tmFlowInfos;
              if(this.infor.country == 'WO') {
                let tmFlowInfos_filter = this.flowData.reduce((r, c) => {
                  let buff = r.find(i => i.authorityCode === c.authorityCode);
                  if(buff) {
                    return r.map( j => {
                      if(j.authorityCode === buff.authorityCode) {
                        j.progress.push({
                          progressDate: c.flowdate,
                          progressEvent: c.flowenname
                        })
                        j.progress = j.progress.sort((a,b) => b.progressDate - a.progressDate)
                      }
                      return j
                    })
                  } else {
                    c.progress = [{
                      progressDate: c.flowdate,
                      progressEvent: c.flowenname
                    }]
                    r.push(c)
                    return r
                  }
                }, [])
                this.flowData = tmFlowInfos_filter.splice(tmFlowInfos_filter.findIndex(i => i.authorityZhName == '中国'), 1).concat(tmFlowInfos_filter.sort((a, b) => {return a.authorityZhName.localeCompare(b.authorityZhName, 'zh-CN')}))
              }
            }
          })
          .catch(error => {
            console.log(error);
          });
      },
      appli() {
        this.webClassService = JSON.parse(this.mineInfor.webClassService);
        this.appliOneShow = true;
        this.appliShow = false;
      },
      appliOne() {
        this.webClassService = JSON.parse(this.mineInfor.webClassService);
        this.webClassService = this.webClassService.slice(0, 4);
        this.appliOneShow = false;
        this.appliShow = true;
      },
      back() {
        this.$router.push({
          path: "/query/fast", //查询结果
          query: {
            step: 1
            // md:"t1_0",
          }
        });
      },
      //  打印功能
      printContent(event) {
        var div_print = document.getElementById("trademarkInfor");
        var newstr = div_print.innerHTML;
        var oldstr = document.body.innerHTML;
        document.body.innerHTML = newstr;
        window.print();
        window.location.reload();
        document.body.innerHTML = oldstr;
        return false;
      },
    },
    computed: {

    },
    filters: {}
  };
</script>

<style lang="less" scoped>
  .state {
    height: 100px !important;
    line-height: 100px !important;
  }

  .service {
    height: 70px !important;
    line-height: 70px !important;
  }

  .cur {
    cursor: pointer;
  }

  #trademarkInfor {
    .btnPdf{
      position: fixed;
      top: 106px;
      right: 1%;
    }
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding-top: 16px;
    .wrapBox {
      width: 1200px;
      margin: 16px auto 0;
      position: relative;
    }
    .trademarkArchives_in {
      background-color: #fff;
      border-radius: 3px;
      padding: 20px 48px 40px;
      /*box-shadow: 0px 0px 14px 11px rgba(239,239,239,0.8);*/
      &.en {
        .trademarkArchivesTop {
          .leibie {
            /*width: auto;*/
            min-width: 72%;
          }
        }
        .trademarkArchivesMiddle {
          & > div {
            .littleTittle {
              width: auto;
              min-width: 237px;
            }
          }
        }
      }
      /*滚动条样式*/
      /*https://www.cnblogs.com/yclblog/p/6806496.html*/
      .test-1::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 6px;
        /*高宽分别对应横竖滚动条的尺寸*/
        height: 4px;
      }
      .test-1::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px #eb5656;
        background: #eb5656;
      }
      .test-1::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        -webkit-box-shadow: inset 0 0 5px #e6e6e6;
        border-radius: 10px;
        background: #e6e6e6;
      }
      .trademarkArchivesTop {
        // overflow: hidden;
        position: relative;
        .leibie {
          /*margin-left: 40px;*/
          /*float: left;*/
          width: 72%;
          position: absolute;
          left: 300px;
          top: 15px;
          .leibieTitle {
            font-size: 14px;
            color: #222222;
          }
          .jutilei {
            overflow-x: hidden;
            overflow-y: auto;
            height: 200px;
            margin-top: 20px;
            border: none;
          }
        }
        .imghover {
          transition: all 0.6s;
          cursor: pointer;
        }
        .imghover:hover {
          transform: scale(1.65);
        }
        .imgBox {
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 35px;
            display: inline-block;
            max-width: 100%;
            max-height: 96%;
            margin: auto;
            text-align: center;
            vertical-align: middle;
            font-weight: bold;
            word-break: break-word;
          }
          .TextSize{
            font-size: 22px;
            line-height: 25px;
          }
        }
      }
      .trademarkName-text {
        /*width: calc(100% - 245px);*/
        display: inline-block;
        line-height: 20px;
      }
      .trademarkArchivesMiddle {
        text-align: left;
        & > div {
          height: 50px;
          border-bottom: 1px solid #e2e2e2;
          line-height: 50px;
          padding-left: 2px;
          .littleTittle {
            display: inline-block;
            width: 147px;
            color: #555;
            font-weight: bold;
          }
          .publicD {
            display: inline-block;
            width: 55%;
          }
          .pubdd {
            width: 43%;
          }
        }
        .trademarkName-content {
          height: auto;
          min-height: 50px;
          /deep/.el-button--mini, /deep/.el-button--mini.is-round {
            padding: 3px 8px;
          }
          & > * {
            vertical-align: middle;
          }
          .littleTittle {
            width: 237px;
          }
          .copy {
            visibility: hidden;
          }
          &:hover {
            .copy {
              visibility: visible;
            }
          }
        }
      }
      .ApplicantInformation, .CorrespondenceInformation {
        width: 100%;
        min-height: 100px;
        margin-top: 20px;
        b {
          font-size: 18px;
          display: block;
          margin-bottom: 10px;
        }
        div {
          min-height: 50px;
          line-height: 50px;
          border-top: 1px solid #dddddd;
          .littleTittle {
            font-weight: bold;
          }
          .trademarkName-text {
            color: #666;
            vertical-align: middle;
          }
          /deep/.el-button--mini, /deep/.el-button--mini.is-round{
            padding: 4px 12px;
          }
        }
        .trademarkName-content{
          .copy {
            visibility: hidden;
          }
          &:hover {
            .copy {
              visibility: visible;
            }
          }
        }
      }
      .classTable {
        width: 100%;
        min-height: 100px;
        margin-bottom: 30px;
        tr {
          text-align: center;
          th {
            height: 50px;
            background: #f4f4f4;
            border: 1px solid #dedede;
            border-bottom: none;
            line-height: 50px;
          }
          th:nth-of-type(3) {
            border-right: 1px solid #dedede;
          }
          td {
            height: 50px;
            border-right: 1px solid #dedede;
            border-bottom: 1px solid #dedede;
          }

          td:nth-of-type(1) {
            border-left: 1px solid #dedede;
            width: 15%;
            vertical-align: middle;
          }
          td:nth-of-type(2) {
            width: 85%;
            vertical-align: middle;
            padding: 12px 20px 35px 20px;
            word-break: break-word;
            line-height: 23px;
            text-align: justify;
          }
          .copy {
            visibility: hidden;
          }
          &:hover {
            .copy {
              visibility: visible;
            }
          }
        }
        .appliover {
          height: 20px;
          overflow: hidden;
        }
        .open {
          height: auto;
        }
        .ckgd {
          width: 100%;
          height: 50px;
          border: 1px solid #dedede;
          border-top: none;
          line-height: 50px;
          text-align: center;
          cursor: pointer;
        }
        .copy{
          position: absolute;
          right: 3px;
          bottom: 3px;
          padding: 5px 15px!important;
        }
      }
      .seniority-box {
        margin-top: 26px;
        h6 {
          font-weight: bold;
          padding-left: 2px;
          color: #555;
          margin-bottom: 16px;
        }
        .seniorityTable {
          width: 100%;
          min-height: 100px;
          text-align: center;
          tr:nth-of-type(1) {
            height: 50px;
            background: #f4f4f4;
            /*border: 1px solid #dedede;*/
            th {
              font-weight: bold;
            }
          }
          tr + tr {
            height: 50px;
            /*border-bottom:  1px solid #dedede;*/
            /*border-right: 1px solid #dedede;*/
            td {
              /*border-left:1px solid #dedede;*/
            }
            td + td {
            }
          }
        }
      }
      .tradeMarkProgre {
        border: none;
        margin-top: 26px;
        span {
          font-weight: bold;
          padding-left: 2px;
        }
        .progressTop {
          margin-top: 16px;
          width: 100%;
          border-radius: 3px;
          font-size: 14px;
          color: #333;
          span {
            display: inline-block;
          }
          tr:nth-of-type(1) {
            height: 50px;
            background: #f2f2f2;
            th:nth-of-type(1) {
              width: 20%;
            }
            th:nth-of-type(2) {
              width: 30%;
            }
            th:nth-of-type(3) {
              width: 40%;
            }
          }
        }
        .progressBottom:nth-child(2) {
          margin-top: 30px;
          .inailw {
            background: url("../../assets/images/query/redjiaotpu.png") no-repeat 20px;
            position: relative;
            img {
              position: absolute;
              left: 32px;
              top: 42px;
            }
          }
        }
        .progressBottom {
          font-size: 14px;
          color: #333;
          width: 100%;
          height: 60px;
          span {
            display: inline-block;
          }
          .inailw {
            background: url("../../assets/images/query/huijiantou.png") no-repeat 20px;
            height: 26px;
            width: 12%;
            position: relative;
            img {
              position: absolute;
              left: 32px;
              top: 42px;
            }
          }
          span:nth-child(2) {
            width: 20%;
          }
          span:nth-child(3) {
            width: 30%;
          }
          span:nth-child(4) {
            width: 30%;
          }
        }
        .progressBottom:last-child {
          .inailw {
            img {
              display: none;
            }
          }
        }
        ul.switch-style {
          display: inline-block;
          font-size: 0;
          border-radius: 3px;
          overflow: hidden;
          margin-bottom: -10px;
          margin-left: 20px;
          li {
            display: inline-block;
            font-size: 13px;
            width: 70px;
            height: 34px;
            color: #555;
            line-height: 32px;
            text-align: center;
            cursor: pointer;
            background-color: #f2f2f2;
            & > * {
              vertical-align: middle;
            }
            &.active {
              background-color: #2f56de;
              color: #FFFFFF;
            }
          }
          li + li {
            border-left: solid 1px #2f56de;
          }
        }
        .progressItem /deep/ .el-collapse-item__header {
          font-weight: bold!important;
          // background: #f2f2f2!important;
          padding-left: 10px;
        }
      }
      .tradeMarkTop {
        min-height: 360px;
        ul {
          li {
            width: 300px;
            min-height: 282px;
            background: #fff;
            display: inline-block;
            margin-right: 20px;
            vertical-align: top;
            .leftLi {
              padding-left: 30px;
              padding-top: 20px;
              .publicD {
                /*display: flex;*/
                /*align-items: center;*/
                margin-bottom: 18px;
                /deep/.el-button--mini, /deep/.el-button--mini.is-round {
                  padding: 3px 8px;
                }
                .littleTittle {
                  display: inline-block;
                  width: 130px;
                  font-weight: bold;
                  color: #000;
                }
                .fontD {
                  display: inline-flex;
                  font-size: 14px;
                  color: #666;
                }
                .copy {
                  display: inline-flex!important;
                  margin-left: 5px;
                  visibility: hidden;
                }

                &:hover{
                  .copy {
                    visibility: visible;
                  }
                }
              }
              .trademarkName{
                display: block!important;
                max-width: 150px!important;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }
            }
            .leftLiOne {
              padding-left: 30px;
              padding-top: 20px;
              span {
                display: inline-block;
              }
              .publicD {
                /*display: flex;*/
                /*align-items: center;*/
                margin-bottom: 18px;
                /deep/.el-button--mini, /deep/.el-button--mini.is-round {
                  padding: 3px 8px;
                }
                .littleTittle {
                  display: inline-block;
                  width: 130px;
                  font-weight: bold;
                  color: #000;
                }
                .fontD {
                  display: inline-flex;
                  font-size: 14px;
                  color: #666;
                }
                .copy {
                  display: inline-flex!important;
                  margin-left: 5px;
                  visibility: hidden;
                }

                &:hover{
                  .copy {
                    visibility: visible;
                  }
                }
              }
            }
          }
          li:nth-of-type(1) {
            width: 215px;
            min-height: 215px;
            background: #fff;
            border: 1px solid #f2f2f2;
            margin-top: 20px;
          }
          li:nth-of-type(2) {
            width: 380px;
            border-right: 1px solid #dddddd;
          }
          li:nth-of-type(3) {
            margin-right: 0;
            width: 330px;
            background: #fff;
          }
          &.active{
            .publicD {
              display: flex;
              /*justify-content: space-between;*/

              .littleTittle {
                font-size: 16px;
                white-space: nowrap;
                width: auto;
                margin-right: 20px;
              }
              .fontD {
                font-size: 14px;
                color: #666;
                display: inline-block;
                /*width: 50%;*/
                margin-right: 20px;
                display: flex;
                white-space: nowrap;
                justify-content: space-between;
              }
            }

            li:nth-of-type(2) {
              width: 430px;
              border-right: 1px solid #dddddd;
            }

            .leftLi .publicD .littleTittle{
              width: auto;
            }
          }
        }
      }
    }
    .btnBack {
      button {
        width: 114px;
        height: 40px;
        background: #2f56de;
        border-radius: 5px;
        color: #fff;
        cursor: pointer;
        margin-top: 25px;
        float: right;
        margin-right: 60px;
      }
      button:hover {
        background: #d73e3f;
      }
    }
    .trademarkArchivesBottom {
      width: 280px;
      height: 50px;
      margin-top: 35px;
      margin-bottom: 110px;
      position: absolute;
      right: 0;
      &.en {
        width: auto;
        span {
          width: auto;
          min-width: 114px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
      span {
        width: 114px;
        height: 40px;
        display: inline-block;
        text-align: center;
        line-height: 40px;
        color: #fff;
        font-size: 16px;
        border-radius: 3px;
      }
      .print {
        background-color: #2f56de;
        margin-right: 42px;
      }
      .error {
        background-color: #434454;
      }
    }
  }
  .leftBtn {
    width: 30px;
    height: 60px;
    background-color: #2f56de;
    border-radius: 5px;
    font-size: 20px;
    text-align: center;
    line-height: 60px;
    font-weight: bold;
    position: absolute;
    left: 5px;
    top: 400px;
    color: #fff;
    cursor: pointer;
  }
  .rightBtn {
    width: 30px;
    height: 60px;
    background-color: #2f56de;
    border-radius: 5px;
    font-size: 20px;
    text-align: center;
    line-height: 60px;
    font-weight: bold;
    position: absolute;
    right: 5px;
    top: 400px;
    color: #fff;
    cursor: pointer;
  }
  .disabledBtn {
    cursor: not-allowed;
    background-color: #ccc;
  }
</style>
<style lang="less">
  @media screen and (max-width: 1400px) {
    #trademarkInfor {
      .wrapBox {
        width: 1000px !important;
        .trademarkArchives_in {
          .trademarkArchivesTop {
            .leibie {
              width: 70%;
            }
          }
        }
      }
    }
    #trademarkInfor
    .trademarkArchives_in
    .tradeMarkProgre
    .progressTop
    tr:nth-of-type(1)
    th:nth-of-type(2) {
      text-indent: 24px !important;
    }
  }
</style>
